<template>
  <div id="page-message-prepchat-manager">
    <aside>
      <header>
        <input
          type="text"
          v-model="keyword"
          placeholder="Tìm kiếm"
          v-on:keyup="search()"
        />
      </header>
      <ul v-if="!isSearch">
        <PageItemConversationManager
          v-for="item in this.$store.state.prepChatGroup.groupsManager"
          v-bind:group="item"
          v-on:openBoxChat="clientOpenBoxChat"
          :key="item.id"
        />
      </ul>
      <ul v-if="isSearch">
        <PageItemConversationManager
          v-for="item in groupSearch"
          v-bind:group="item"
          v-on:openBoxChat="clientOpenBoxChat"
          :key="item.id"
        />
      </ul>
    </aside>
    <main>
      <div class="block-chat" v-if="groupActive">
        <PageBlockChatManager v-bind:group="groupActive" />
      </div>
      <div
        v-if="
          this.$store.state.prepChatGroup.groupsManager.length > 0 &&
          !groupActive
        "
        style="
          text-align: center;
          align-items: center;
          height: 100%;
          padding-top: 5rem;
        "
      >
        <h3>Vui lòng chọn cuộc trò chuyện bất kỳ</h3>
      </div>
      <div
        v-if="this.$store.state.prepChatGroup.groupsManager.length == 0"
        style="
          text-align: center;
          align-items: center;
          height: 100%;
          padding-top: 5rem;
        "
      >
        <h3>Hiện không có cuộc trò chuyện nào...</h3>
      </div>
    </main>
  </div>
</template>

<script>
import group from "../mixins/group";
import authentication from "../mixins/authentication";
import user from "../mixins/user";
import message from "../mixins/message";
import PageItemConversationManager from "./PageItemConversationManager.vue";
import PageBlockChatManager from "./PageBlockChatManager.vue";
export default {
  mixins: [group, authentication, user, message],
  components: {
    PageItemConversationManager,
    PageBlockChatManager,
  },
  data() {
    return {
      isLogin: false,
      user: null,
      groupActive: null,
      isSearch: false,
      keyword: "",
      groupSearch: [],
    };
  },
  methods: {
    clientOpenBoxChat(group) {
      this.groupActive = null;
      this.chooseGroup(group);
    },
    clientSendMessage(context) {
      this.sendMessage(context.data, context.box);
    },

    async login() {
      let userData = null;
      //pmprep
      if (localStorage.getItem("data_user")) {
        let localUser = JSON.parse(localStorage.getItem("data_user"));
        userData = {
          uid: Number(localUser.id),
          email: localUser.email,
          displayName: localUser.name,
          photoURL: localUser.avatar,
          type: "prep_user",
        };
      }

      if (userData) {
        this.user = userData;
        this.isLogin = true;
        await this.fetchGroupByManager();
      }
    },
    async chooseGroup(group = null) {
      if (group) {
        let listUser = [];
        await this.fetchUsersByGroup(group).then(
          (response) => (listUser = response)
        );
        this.$store.commit("prepChatGroup/updateGroupUsers", {
          id: group.id,
          value: listUser,
        });
        this.pushToListBoxBottom(group);
      }
    },
    async sendMessage(data, oldGroup) {
      const sentAt = new Date();
      const message = await this.saveMessage(
        data.message,
        sentAt,
        data.id,
        data.type
      );
      if (message) {
        const group = {
          ...oldGroup,
          ...{
            users: null,
            modifiedAt: sentAt,
            recentMessage: { ...message, ...{ readBy: [this.user.uid] } },
          },
        };
        this.updateGroup(group);
      }
    },
    pushToListBoxBottom(obj) {
      const cloneObject = JSON.parse(JSON.stringify(obj));
      cloneObject.hide = false;
      cloneObject.show = true;
      cloneObject.showMessage = true;
      cloneObject.multiUser = false;

      this.groupActive = cloneObject;
    },
    selectFistGroup() {
      setTimeout(() => {
        if (this.isLogin && this.$store.state.prepChatGroup.groupsManager) {
          if (this.groupActive == null) {
            this.chooseGroup(this.$store.state.prepChatGroup.groupsManager[0]);
          }
        }
      }, 2000);
    },
    search() {
      let vm = this;
      const keyword = vm.keyword.toLowerCase();
      if (keyword.length) {
        vm.groupSearch = [];
        vm.isSearch = true;
        for (
          let index = 0;
          index < this.$store.state.prepChatGroup.groupsManager.length;
          index++
        ) {
          let count = 0;
          const currentGroup =
            this.$store.state.prepChatGroup.groupsManager[index];
          //search name group
          let groupName = currentGroup.name.toLowerCase();
          if (groupName.includes(keyword)) {
            count++;
          }
          //search name user
          if (currentGroup.users) {
            for (
              let subIndex = 0;
              subIndex < currentGroup.users.length;
              subIndex++
            ) {
              const currentUser = currentGroup.users[subIndex];
              if (currentUser.uid != this.user.uid) {
                let displayName = currentUser.displayName.toLowerCase();
                if (displayName.includes(keyword)) {
                  count++;
                }
                //search email
                let email = currentUser.email.toLowerCase();
                if (email.includes(keyword)) {
                  count++;
                }
              }
            }
          }

          if (count > 0) {
            vm.groupSearch.push(currentGroup);
          }
        }
      } else {
        vm.isSearch = false;
        vm.groupSearch = [];
      }
    },
  },
  // watch: {
  //     async user(val) {
  //         if (!val) return;
  //         this.saveUserToLocalStorage(val);
  //         this.saveUserToStore(val);
  //         // await this.fetchUsers();
  //         const exist = await this.checkUserExisted(val);
  //         if (exist) {
  //             //update info
  //             // this.updateUser(val)
  //             await this.fetchGroupByManager();
  //             return;
  //         }
  //         this.saveUser(val);
  //     }
  // },
  created() {
    this.login();
    this.selectFistGroup();
  },
};
</script>

<style lang="scss">
#page-message-prepchat-manager {
  display: flex;
  background: #eff3f7;
  margin: 0 auto;
  font-size: 0;
  border-radius: 5px;
  overflow: hidden;
  aside {
    width: 300px;
    height: 90vh;
    background-color: #3b3e49;
    display: inline-block;
    font-size: 15px;
    vertical-align: top;
  }
  main {
    display: inline-block;
    font-size: 15px;
    vertical-align: top;
    width: -webkit-fill-available;
  }

  aside header {
    padding: 30px 20px;
  }
  aside input {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 50px 0 20px;
    background-color: #5e616a;
    border: none;
    border-radius: 3px;
    color: #fff;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
    background-repeat: no-repeat;
    background-position: 160px;
    background-size: 40px;
  }
  aside input::placeholder {
    color: #fff;
  }
  aside ul {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    overflow-y: scroll;
    height: 690px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  aside li {
    padding: 10px 0;
    display: flex;
  }
  aside ul::-webkit-scrollbar {
    display: none;
  }
  aside li:hover {
    background-color: #5e616a;
  }
  h2,
  h3 {
    margin: 0;
  }
  aside li img {
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    object-fit: cover;
  }
  aside li div {
    display: inline-block;
    vertical-align: top;
    margin-top: 12px;
  }
  aside li h2 {
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 5px;
    // inline-size: 120px;
    overflow: hidden;
    text-transform: capitalize;
  }
  aside li h3 {
    font-size: 12px;
    color: #7e818a;
    font-weight: normal;
    inline-size: 150px;
    overflow: hidden;
  }

  .status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
  }
  .green {
    background-color: #f3f4f9;
  }
  .orange {
    background-color: #ff725d;
  }
  .blue {
    background-color: #6fbced;
    margin-right: 0;
    margin-left: 7px;
  }

  main header {
    height: 90px;
    padding: 15px;
  }
  main header > * {
    display: inline-block;
    vertical-align: top;
  }
  main header img:first-child {
    border-radius: 50%;
    object-fit: cover;
  }
  main header img:last-child {
    width: 24px;
    margin-top: 8px;
    display: none;
  }
  main header div {
    margin-left: 10px;
    margin-right: 145px;
  }
  main header h2 {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  main header h3 {
    font-size: 14px;
    font-weight: normal;
    color: #7e818a;
  }

  #chat {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    overflow-y: scroll;
    // height:535px;
    height: 78vh;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    background: white;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  #chat li {
    padding: 10px 30px;
  }
  #chat h2,
  #chat h3 {
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
    text-transform: capitalize;
  }
  #chat h3 {
    color: #bbb;
    margin-left: 5px;
    font-size: 10px;
  }
  #chat .entete {
    margin-bottom: 5px;
  }
  #chat .message {
    white-space: pre-line;
    padding: 5px 10px;
    color: black;
    line-height: 25px;
    max-width: 90%;
    display: inline-block;
    text-align: left;
    border-radius: 5px;
  }
  #chat .message p {
    margin-bottom: 0px;
  }
  #chat .me {
    text-align: right;
    position: relative;
  }
  #chat .you .message {
    background-color: #f3f4f9;
  }
  #chat .me .message {
    background-color: #377dff;
    color: white;
  }
  #chat .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
  }
  #chat .you .triangle {
    border-color: transparent transparent #f3f4f9 transparent;
    margin-left: 5px;
  }
  #chat .me .triangle {
    border-color: transparent transparent #377dff transparent;
    position: absolute;
    right: 40px;
    top: 30px;
  }

  main footer {
    padding: 15px;
  }
  main footer textarea {
    resize: none;
    border: none;
    display: block;
    width: 80%;
    height: 80px;
    border-radius: 3px;
    padding: 20px;
    font-size: 13px;
    margin-bottom: 0px;
    background: white;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  }
  main footer textarea::placeholder {
    color: #ddd;
  }
  main footer img {
    height: 30px;
    cursor: pointer;
    margin: 15px;
  }
  main footer a {
    float: right;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    background: #377dff;
    vertical-align: top;
    padding: 20px;
    color: white;
    border-radius: 10px;
    -webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  }
}
</style>
