<template>
  <div>
    <PageManager />
  </div>
</template>

<script>
import PageManager from "@/plugins/prepChat/components/PageManager.vue";
export default {
  components: {
    PageManager,
  },
};
</script>

<style></style>
