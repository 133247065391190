var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":_vm.groupImage}}),_c('div',[_c('h2',[_vm._v(_vm._s(this.groupName))])])]),_c('ul',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({ always: false }),expression:"{ always: false }"}],staticClass:"blockChat",attrs:{"id":"chat"},on:{"v-chat-scroll-top-reached":_vm.loadMore}},_vm._l((_vm.listMessage),function(contentMessage,index){return _c('li',{key:index,class:{
          me:
            (contentMessage.sentBy == _vm.prepUser.uid && !_vm.isThirdView) ||
            (_vm.isThirdView && contentMessage.sentBy != _vm.group.createdBy),
          you:
            (contentMessage.sentBy != _vm.prepUser.uid && !_vm.isThirdView) ||
            (_vm.isThirdView && contentMessage.sentBy == _vm.group.createdBy),
        }},[_c('div',{staticClass:"entete"},[_c('span',{staticClass:"status green"}),_c('h2',[_vm._v(_vm._s(_vm.findNameUser(contentMessage.sentBy)))]),_c('h3',[_vm._v("("+_vm._s(_vm._f("formatUnix")(contentMessage.sentAt.seconds))+")")])]),_c('div',{staticClass:"triangle"}),_c('div',{staticClass:"message"},[(contentMessage.type == 'text')?_c('div',{domProps:{"innerHTML":_vm._s(contentMessage.messageText)}}):_vm._e(),(contentMessage.type == 'image')?_c('div',{staticClass:"message-image"},_vm._l((contentMessage.messageText),function(imageSrc,indexImage){return _c('img',{key:indexImage,attrs:{"src":imageSrc},on:{"click":function($event){return _vm.openPreview(imageSrc)}}})}),0):_vm._e(),(contentMessage.type == 'tag-marking')?_c('div',{staticClass:"message-tag-marking"},[_c('span',[_vm._v("#Hỏi đáp chấm bài")]),_c('p',[_vm._v(" Course: "),_c('a',{attrs:{"href":_vm.tagMarkingLink(contentMessage.messageText),"target":"_blank"}},[_vm._v(_vm._s(_vm.tagMarkingName(contentMessage.messageText)))])]),(_vm.tagMarkingLesson(contentMessage.messageText) != 'undefined')?_c('p',[_vm._v(" Lesson: "+_vm._s(_vm.tagMarkingLesson(contentMessage.messageText))+" ")]):_vm._e(),(
                _vm.tagMarkingTicketID(contentMessage.messageText) != 'undefined'
              )?_c('p',[_vm._v(" Ticket ID: #"+_vm._s(_vm.tagMarkingTicketID(contentMessage.messageText))+" ")]):_vm._e()]):_vm._e()])])}),0),_c('Preview',{attrs:{"url":_vm.urlPreview},on:{"hidePreview":_vm.hidePreview}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }